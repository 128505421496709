<template>
	<div :class="['alphabetical-navigation', { closed: !isActive }]">
		<div class="navigation-title" @click="switchState">
			Alphabetical list of terms
			<div class="navigation-title--controller" :class="{ inactive: !isActive }">
				<icon icon="v2-chevron-down" colour="transparent" />
			</div>
		</div>
		<div v-if="groups.length" :class="['letters-list', { closed: !isActive }]">
			<router-link
				class="letter-link"
				v-for="group in groups"
				:key="group.key"
				:to="generateAnchor(group.key)">
				{{ group.title }}
				<span class="counter">[{{ group.items.length }}]</span>
			</router-link>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			groups: {
				type: Array,
				default: () => []
			}
		},
		data: () => {
			return {
				isActive: true
			};
		},
		computed: {},
		methods: {
			switchState () {
				this.isActive = !this.isActive;
			},
			generateAnchor (letter) {
				return {
					hash: `#${letter}`
				};
			}
		}
	};

</script>

<style lang="scss" scoped>

	.alphabetical-navigation {
		display: flex;
		flex-direction: column;
		background-color: $c-bg-white;
		padding: 12px;
		border-radius: 4px;
		border: 1px solid $c-gray-d2;
		gap: 8px;
		transition: all 0.1s ease-in-out;

		&.closed {
			gap: 0;
		}

		.navigation-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			@include font(16px, $c-dark-navy, 500, 24px);

			&--controller {
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;

				transition: all 0.3s ease-in-out;
				transform: rotate(0deg);

				&.inactive {
					transform: rotate(180deg);
				}
			}
		}

		.letters-list {
			transition: all 0.1s ease-in-out;
			height: auto;
			max-height: 500px;
			overflow: auto;

			&.closed {
				max-height: 0;
				visibility: hidden;
				opacity: 0;
			}

			.letter-link {
				@include font(16px, $c-dark-navy, 400, 18px);
				display: flex;
				align-items: center;
				text-transform: uppercase;
				padding: 4px 6px;
				border-radius: 4px;
				transition: all 0.1s ease-in-out;

				&:hover {
					background-color: $c-gray-l;
				}

				.counter {
					@include font(10px, $c-gray-d3, 500, 13px);
					margin-left: 4px;
				}
			}
		}
	}

</style>
