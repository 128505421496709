<template>
	<div class="terms-list-container">
		<div
			class="groups-list"
		>
			<terms-group
				v-for="group in groups"
				:key="group.key"
				:group="group"
			/>
		</div>
	</div>
</template>

<script>
	import TermsGroup from '@/components/glossary/TermsGroup.vue';

	export default {
		components: {
			TermsGroup
		},
		props: {
			groups: {
				type: Array,
				default: () => []
			}
		},
		data: () => {
			return {

			};
		},
		computed: {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

</style>
